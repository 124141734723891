"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
// import store from './store'
// import firebase from 'firebase'
vue_1.default.use(vue_router_1.default);
var indexRouter = new vue_router_1.default({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior: function (to, from, savedPosition) {
        var position = savedPosition || { x: 0, y: 0 };
        return position;
    },
    routes: [
        {
            path: '',
            name: 'Home',
            component: function () { return Promise.resolve().then(function () { return require('@/views/home.vue'); }); },
            props: true,
        },
        {
            path: '/chart',
            name: 'Chart',
            component: function () { return Promise.resolve().then(function () { return require('@/views/chart.vue'); }); },
            props: true,
        },
    ]
});
// router.beforeEach((to, from, next) => {
//   store.state.isSuccessSubmit = false
//   store.state.isLoadingSubmit = false
//   store.state.isErrorSubmit = null
//   store.state.showSideMenu = false
//   if (!store.getters.currentUserExists) {
//     if (
//       to.name !== 'Home' &&
//       to.name !== 'CompleteCompanySignUp' &&
//       to.name !== 'Login' &&
//       to.name !== 'CreateAccount' &&
//       to.name !== 'ResetPassword' &&
//       to.name !== 'Terms' &&
//       to.name !== 'Contact' &&
//       to.name !== 'FAQ' &&
//       to.name !== 'Privacy' &&
//       to.name !== 'Cookies'
//     ) {
//       next({name: 'Login'})
//     } else { next() }
//   }
//   else if (
//     (
//       to.name === 'Login' ||
//       to.name === 'CreateAccount' ||
//       to.name === 'ResetPassword'
//     ) &&
//     store.getters.currentUserExists
//   ) {
//     next({name: 'Reminders'})
//   }
//   else {
//     next()
//   }
// })
exports.default = indexRouter;
