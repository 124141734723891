"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var App_vue_1 = require("./App.vue");
var router_1 = require("./router");
var app_1 = require("firebase/app");
var analytics_1 = require("firebase/analytics");
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyDo2tcy1_c5K7O9WKubvNR-vvKVPmy2wg0",
    authDomain: "showpig-e8eb2.firebaseapp.com",
    projectId: "showpig-e8eb2",
    storageBucket: "showpig-e8eb2.appspot.com",
    messagingSenderId: "890022782175",
    appId: "1:890022782175:web:f393d967281649c55bf56a",
    measurementId: "G-HSLC3W0KMC"
};
// Initialize Firebase
var app = app_1.initializeApp(firebaseConfig);
var analytics = analytics_1.getAnalytics(app);
var fireApp = app_1.initializeApp(firebaseConfig);
// Vue.prototype.$firebase = fb
// Vue.prototype.$storage = fb.storage()
// Vue.prototype.$firestore = fs
vue_1.default.config.productionTip = false;
new vue_1.default({
    render: function (h) { return h(App_vue_1.default); },
    router: router_1.default,
}).$mount('#app');
vue_1.default.use(vue_router_1.default);
