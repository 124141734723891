var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.keyValue,attrs:{"id":"home"}},[_c('div',{staticClass:"hero is-fullheight",style:({ backgroundImage: `url(${'images/cloud-background.jpg'})`, backgroundPosition: '50% 80%' })},[_vm._m(0)]),_vm._m(1),_vm._m(2),_c('section',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-vcentered is-centered is-multiline"},[_vm._m(3),_vm._m(4),_vm._l((_vm.images),function(i){return [_c('div',{staticClass:"column is-4"},[_c('img',{staticClass:"gallery-image",attrs:{"src":'./images/' + i}})])]})],2),_vm._m(5)])]),_c('br'),_c('br'),_c('br'),(_vm.thankYouPopup)?_c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-background",on:{"click":function($event){_vm.thankYouPopup = _vm.falseValue}}}),_c('div',{staticClass:"modal-close is-large",on:{"click":function($event){_vm.thankYouPopup = _vm.falseValue}}}),_vm._m(6)]):_vm._e(),(_vm.newsletter)?_c('div',{staticClass:"modal is-active"},[_c('div',{staticClass:"modal-background",on:{"click":function($event){_vm.newsletter= _vm.falseValue}}}),_c('div',{staticClass:"modal-close is-large",on:{"click":function($event){_vm.newsletter = _vm.falseValue}}}),_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-card-body"},[_c('NewsletterForm')],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"serif-font",staticStyle:{"font-size":"4rem"}},[_vm._v("SHOWPIG")]),_c('hr',{staticClass:"short-black"}),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"serif-font is-size-5"},[_vm._v("Black & Blue Tattoo")]),_c('p',{staticClass:"is-size-7"},[_vm._v("3290 22nd Street")]),_c('p',{staticClass:"is-size-7"},[_vm._v("San Francisco")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"pt-6 pb-6 mt-6 mb-6"},[_c('div',{staticClass:"columns is-centered is-vcentered"},[_c('div',{staticClass:"column is-narrow"},[_c('a',{staticClass:"button tattoo-request is-borderless",attrs:{"href":"https://form.jotform.com/230326932393052","target":"_blank"}},[_c('span',[_vm._v("Tattoo Request")])])]),_c('div',{staticClass:"column is-narrow"},[_c('a',{staticClass:"button is-borderless",attrs:{"href":"http://eepurl.com/imULDU","target":"_blank"}},[_c('span',[_vm._v("Newsletter")])])]),_c('div',{staticClass:"column is-narrow"},[_c('a',{staticClass:"button is-borderless",attrs:{"href":"http://eepurl.com/imULDU","target":"_blank"}},[_c('span',[_vm._v("Cancellation List")])])])]),_c('div',{staticClass:"columns is-centered is-vcentered"},[_c('div',{staticClass:"column is-narrow"},[_c('a',{staticClass:"button is-borderless",attrs:{"href":"https://www.instagram.com/diamondshowpig/","target":"_blank"}},[_c('img',{attrs:{"src":"/images/instagram.svg","height":"25px","width":"25px"}})])]),_c('div',{staticClass:"column is-narrow"},[_c('a',{staticClass:"button is-borderless",attrs:{"href":"https://www.pinterest.com/diamondshowpig/","target":"_blank"}},[_c('img',{attrs:{"src":"/images/pinterest.svg","height":"25px","width":"25px"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-6 mt-6 mb-6"},[_c('hr',{staticClass:"short-black"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column is-4"},[_c('img',{attrs:{"src":"/images/headshot.png"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"is-size-5 has-text-left"},[_c('p',{staticClass:"mb-5"},[_vm._v("Carter earned her Bachelor of Fine Arts degree from Carnegie Mellon University, with a specialization in intricate drawings and paintings. Her educational background positioned her to excel in the art of fine-line tattooing.")]),_c('p',[_vm._v("Her artistic interests include scientific illustration drawings and surrealism, reflecting a multifaceted approach to her craft. Carter's professional journey is marked by a commitment to diverse projects, demonstrating her ability to thrive in a variety of artistic endeavors.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-6"},[_c('a',{staticClass:"button is-large has-text-weight-normal",attrs:{"href":"https://www.instagram.com/diamondshowpig/","target":"_blank"}},[_c('span',[_vm._v("See All Work")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-card-body"},[_c('div',{staticClass:"serif-font",staticStyle:{"font-size":"5rem"}},[_vm._v("Thank you!")]),_c('br'),_c('div',{staticClass:"is-size-5"},[_vm._v("I'll get back to you in 3-4 business days. :)")]),_c('br')])])
}]

export { render, staticRenderFns }